<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          FPS Helper: FPS游戏实用工具
        </h1>
      </v-col>
    </v-row>
    <v-item-group active-class="primary">
      <v-container>
        <v-row>
          <v-col v-for="item in items" :key="item[0]" cols="12" md="4">
            <v-item>
              <v-card
                class="d-flex align-center grey lighten-4"
                height="200"
                @click="onSelect(item[1])"
              >
                <div color="white" class="text-h2 flex-grow-1 text-center">
                  {{ item[0] }}
                </div>
                <!-- <v-scroll-y-transition>
                  <div v-if="active" class="text-h2 flex-grow-1 text-center">
                    {{ names[n - 1] }}
                  </div>
                </v-scroll-y-transition> -->
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
    <v-divider />
    <p />
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-row justify="center">
          <v-col cols="12" sm="8">
            <v-card outlined>
              <v-card-title class="display-1">关于本站</v-card-title>
              <v-card-text class="title text-left">
                &nbsp;&nbsp;&nbsp;&nbsp;本网站旨在为广大FPS游戏爱好者免费提供各类实用工具，
                目前已推出了鼠标灵敏度转换工具，此后还将推出反应测试、屏幕测试、枪法练习等其他工具，敬请期待
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MainPage",

  metaInfo: {
    title: "FPS Helper：免费易用的FPS实用工具", // set a title
    meta: [
      {
        // set meta
        name: "keywords",
        content:
          "FPS游戏, \
          射击游戏, \
          CS:GO, \
          反应测试, \
          鼠标灵敏度转换, \
          战地, \
          第一人称射击游戏, \
          APEX,\
          APEX英雄, \
          瓦洛兰特, \
          守望先锋, ",
      },
      {
        // set meta
        name: "description",
        content:
          "本网站旨在为广大FPS游戏爱好者免费提供各类实用工具，\
                目前已推出了鼠标灵敏度转换工具，此后还将推出反应测试、屏幕测试、枪法练习等其他工具，敬请期待",
      },
    ],
    link: [
      {
        // set link
        rel: "asstes",
        href: "http://fpshelper.com/",
      },
    ],
  },
  data: () => ({
    items: [
      ["灵敏度转换器", "/conv"],
      ["灵敏度转换器高级版", "/advanced"],
      ["练枪模式（测试版）", "/singleshot"],
      ["有待更新", "/"],
    ],
  }),
  methods: {
    onSelect: function (target) {
      this.$router.push(target);
    },
  },
};
</script>
