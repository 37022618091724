<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
        <v-btn v-on:click="onMainClick" text class="title">FPSHelper</v-btn>
      </div>

      <v-spacer></v-spacer>

      <v-btn v-on:click="onAboutClick" text>
        <span class="title">关于</span>
        <!-- <v-icon>mdi-open-in-new</v-icon> -->
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer padless class="font-weight-medium">
      <v-col class="text-center" cols="12">
        <v-btn icon v-on:click="onAboutClick" class="mx-4 black--text">
          <v-icon>mdi-email</v-icon>
        </v-btn>
        <v-btn icon v-on:click="onAboutClick" class="mx-4 black--text">
          <v-icon>mdi-qqchat</v-icon>
        </v-btn>
        <v-btn icon v-on:click="onAboutClick" class="mx-4 black--text">
          <v-icon>mdi-dots-horizontal-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>FPSHelper</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  methods: {
    onAboutClick: function () {
      this.$router.push("/about");
    },
    onMainClick: function () {
      this.$router.push("/");
    },
  },
};
</script>
