import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueGtag from "vue-gtag";
import MetaInfo from 'vue-meta-info'

Vue.use(VueGtag, {
  config: { id: "G-9W2C70CWF6" }
}, router);
Vue.use(MetaInfo);

Vue.config.productionTip = false

// eslint-disable-next-line
const _hmt = _hmt || [];
// eslint-disable-next-line
window._hmt = _hmt;

(function () {
  const hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?e4211cfa1d6510c3c0b76200a1954683";
  const s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
  console.log("INSTALL OK");
})();

router.beforeEach((to, from, next) => {
  if (to.path) {
    // eslint-disable-next-line
    if (_hmt) {
      // eslint-disable-next-line
      _hmt.push(['_trackPageview', to.fullPath]);
    }
  }
  next();
})

new Vue({
  router,
  vuetify,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount('#app')
