<template>
  <v-container>
    <v-row class="text-center">
      <!-- <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col> -->
      <v-col cols="12"></v-col>
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">鼠标灵敏度转换器</h1>

        <!-- <p class="subheading font-weight-regular">
          Mouse Sensitivity Converter / Calculator
        </p> -->
        <!-- <div class="mb-3"></div> -->
        <a href="http://www.fpshelper.com/advanced"
          >觉得参数不够详细/转换不够准确？免费使用-->鼠标灵敏度转换器高级版</a
        >
      </v-col>

      <v-col cols="12">
        <!-- <v-row justify="space-around">
          <v-col class="d-flex" cols="12" sm="5">
            <v-select
              :items="convModes"
              label="转换模式"
              v-model="mode"
              @change="onConvModeSet"
              outlined
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="5">
            <v-slider
              :label="slideLabel"
              class="align-center"
              :max="200"
              :min="1"
              v-model="dist"
              :disabled="slideDisable"
              @input="convert"
            >
              <template v-slot:append>
                <v-text-field
                  class="mt-0 pt-0"
                  outlined
                  dense
                  single-line
                  type="number"
                  style="width: 80px"
                  v-model="dist"
                  :disabled="slideDisable"
                ></v-text-field>
              </template>
            </v-slider>
          </v-col>
        </v-row> -->

        <v-divider />
        <p />
        <h2 class="display-1 font-weight-bold mb-3">源游戏灵敏度参数</h2>
        <p />

        <v-row justify="start">
          <!-- <v-col class="d-flex" cols="12" sm="2">
            <v-select
              :items="screenRT"
              :label="screenRTLabel"
              :disabled="screenRTDisable"
              v-model="game1Data.screenRT"
              item-text="text"
              item-value="value"
              @change="onScreenRTSet"
              outlined
            ></v-select>
          </v-col> -->
          <!-- <v-col class="d-flex" cols="12" sm="2">
            <v-text-field
              :disabled="szDisable"
              :label="szLabel"
              v-model="game1Data.screenSZ"
              @input="onScreenSzSet"
              outlined
            ></v-text-field>
          </v-col> -->
          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              :items="gameRT"
              :label="gameRTLabel"
              :disabled="gameRTDisable"
              v-model="game1Data.gameRT"
              item-text="text"
              item-value="value"
              @change="onGameRTSet"
              outlined
            ></v-select>
          </v-col>
          <!-- <v-col class="d-flex" cols="12" sm="2">
            <v-text-field
              label="DPI"
              v-model="game1Data.dpi"
              @input="onDPISet"
              outlined
            ></v-text-field>
          </v-col> -->
          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              :items="games"
              label="游戏名称"
              v-model="game1Data.game"
              @change="onGame1Set"
              outlined
            ></v-select>
          </v-col>
          <!-- <v-col class="d-flex" cols="12" sm="2">
            <v-select
              label="射击模式"
              v-model="game1Data.mode"
              :items="gameInfo1.modes"
              @change="onGame1ModeSet"
              outlined
            ></v-select>
          </v-col> -->
          <!-- <v-col class="d-flex" cols="12" sm="2">
            <v-text-field
              :label="fovLabel"
              :disabled="game1Layout.fovDisable"
              v-model="game1Data.fov"
              @input="convert"
              outlined
            ></v-text-field>
          </v-col> -->
          <v-col class="d-flex" cols="12" sm="4">
            <v-text-field
              :label="game1Layout.dt1Label"
              :disabled="game1Layout.dt1Disable"
              :readonly="game1Layout.dt1RO"
              v-model="game1Data.val1"
              @input="convert"
              outlined
            ></v-text-field>
          </v-col>
          <!-- <v-col class="d-flex" cols="12" sm="2">
            <v-text-field
              :label="game1Layout.dt2Label"
              :disabled="game1Layout.dt2Disable"
              :readonly="game1Layout.dt2RO"
              v-model="game1Data.val2"
              @input="convert"
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="12" sm="2">
            <v-text-field
              :label="game1Layout.dt3Label"
              :disabled="game1Layout.dt3Disable"
              :readonly="game1Layout.dt3RO"
              v-model="game1Data.val3"
              @input="convert"
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="5" sm="2">
            <v-select
              :items="game1Layout.op1Item"
              :label="game1Layout.op1Label"
              :disabled="game1Layout.op1Disable"
              v-model="game1Data.op1"
              @change="convert"
              outlined
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="5" sm="2">
            <v-select
              :items="game1Layout.op2Item"
              :label="game1Layout.op2Label"
              :disabled="game1Layout.op2Disable"
              v-model="game1Data.op2"
              @change="convert"
              outlined
            ></v-select>
          </v-col> -->
        </v-row>

        <v-divider />
        <p />
        <h2 class="display-1 font-weight-bold mb-3">目标游戏灵敏度参数</h2>
        <p />

        <v-row justify="start">
          <!-- <v-col class="d-flex" cols="12" sm="2">
            <v-select
              :items="screenRT"
              :label="screenRTLabel"
              :disabled="screenRTDisable"
              v-model="game2Data.screenRT"
              @change="convert"
              outlined
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="2">
            <v-text-field
              :disabled="szDisable"
              :label="szLabel"
              v-model="game2Data.screenSZ"
              @input="convert"
              outlined
            ></v-text-field>
          </v-col> -->
          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              :items="gameRT"
              :label="gameRTLabel"
              :disabled="gameRTDisable"
              v-model="game2Data.gameRT"
              item-text="text"
              item-value="value"
              @change="convert"
              outlined
            ></v-select>
          </v-col>
          <!-- <v-col class="d-flex" cols="12" sm="2">
            <v-text-field
              label="DPI"
              v-model="game2Data.dpi"
              @input="convert"
              outlined
            ></v-text-field>
          </v-col> -->
          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              :items="games"
              label="游戏名称"
              v-model="game2Data.game"
              @change="onGame2Set"
              outlined
            ></v-select>
          </v-col>
          <!-- <v-col class="d-flex" cols="12" sm="2">
            <v-select
              label="射击模式"
              :items="gameInfo2.modes"
              v-model="game2Data.mode"
              @change="onGame2ModeSet"
              outlined
            ></v-select>
          </v-col> -->
          <!-- <v-col class="d-flex" cols="12" sm="2">
            <v-text-field
              :label="fovLabel"
              :disabled="game2Layout.fovDisable"
              v-model="game2Data.fov"
              @input="convert"
              outlined
            ></v-text-field>
          </v-col> -->
          <v-col class="d-flex" cols="12" sm="4">
            <v-text-field
              :label="game2Layout.dt1Label"
              :disabled="game2Layout.dt1Disable"
              :readonly="game2Layout.dt1RO"
              v-model="game2Data.val1"
              @input="convert"
              outlined
            ></v-text-field>
          </v-col>
          <!-- <v-col class="d-flex" cols="12" sm="2">
            <v-text-field
              :label="game2Layout.dt2Label"
              :disabled="game2Layout.dt2Disable"
              :readonly="game2Layout.dt2RO"
              v-model="game2Data.val2"
              @input="convert"
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="12" sm="2">
            <v-text-field
              :label="game2Layout.dt3Label"
              :disabled="game2Layout.dt3Disable"
              :readonly="game2Layout.dt3RO"
              v-model="game2Data.val3"
              @input="convert"
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="5" sm="2">
            <v-select
              :items="game2Layout.op1Item"
              :label="game2Layout.op1Label"
              :disabled="game2Layout.op1Disable"
              v-model="game2Data.op1"
              @change="convert"
              outlined
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="5" sm="2">
            <v-select
              :items="game2Layout.op2Item"
              :label="game2Layout.op2Label"
              :disabled="game2Layout.op2Disable"
              v-model="game2Data.op2"
              @change="convert"
              outlined
            ></v-select>
          </v-col> -->
        </v-row>

        <v-row justify="center">
          <v-col cols="12" sm="8">
            <v-card outlined>
              <v-card-title class="display-1">使用说明</v-card-title>
              <v-card-text class="title text-left">
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;鼠标灵敏度转换器用于将源游戏的鼠标灵敏度转换为目标游戏的鼠标灵敏度（鼠标灵敏度一般可以在游戏设置中找到），
                  使用时用户需要在源游戏参数区域和目标游戏参数区域分别填写各项参数，转换结果将会在目标游戏参数的对应项目实时更新。
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" sm="8">
            <v-card outlined>
              <v-card-title class="display-1"
                >转换器支持的游戏名称及中英文对照</v-card-title
              >
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">中文</th>
                      <th class="text-left">英文</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(k, v) in eng2ch" :key="k">
                      <td>{{ k }}</td>
                      <td>{{ v }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  ConvModes,
  Games,
  ScreenRatio,
  GameRatio,
  GameInfo,
  GetRatio,
} from "../conv.js";

export default {
  name: "SensConv2",
  metaInfo: {
    title: "鼠标灵敏度转换器：免费易用的FPS实用工具", // set a title
    meta: [
      {
        // set meta
        name: "keywords",
        content:
          "鼠标灵敏度转换器，Mouse Sensitivity Converter，灵敏度换算，FPS游戏，鼠标灵敏度转换，游戏灵敏度换算，CS，CF，穿越火线，使命召唤，战地，守望先锋",
      },
      {
        // set meta
        name: "description",
        content:
          "本工具用于在不同游戏间转换（换算）鼠标灵敏度，支持多种常见射击（FPS）游戏如CS:GO、使命召唤(Call Of Duty)、战地(Battlefield)、瓦洛兰特(Valorant)、守望先锋(Overwatch)等，本工具完全免费且直观易用，并且会持续更新",
      },
    ],
    link: [
      {
        // set link
        rel: "asstes",
        href: "http://fpshelper.com/conv/",
      },
    ],
  },
  methods: {
    clearGameLayout: function (layout, data) {
      layout.dt1Label =
        layout.dt2Label =
        layout.dt3Label =
        layout.op1Label =
        layout.op2Label =
          "无需填写";
      layout.op1Item = [];
      layout.op2Item = [];
      layout.fovDisable =
        layout.dt1Disable =
        layout.dt2Disable =
        layout.dt3Disable =
        layout.op1Disable =
        layout.op2Disable =
          true;
      layout.dt1RO = layout.dt2RO = layout.dt3RO = false;
      data.screenRT =
        data.screenSZ =
        data.gameRT =
        data.dpi =
        data.fov =
        data.game =
        data.mode =
          "";
      data.val1 = data.val2 = data.val3 = "";
      data.op1 = data.op2 = "";
    },

    clearLayout: function () {
      this.clearGameLayout(this.game1Layout, this.game1Data);
      this.clearGameLayout(this.game2Layout, this.game2Data);
    },

    // onScreenRTSet: function () {
    //   this.game2Data.screenRT = { ...this.game1Data.screenRT };
    //   this.convert();
    // },
    // onScreenSzSet: function () {
    //   this.game2Data.screenSZ = this.game1Data.screenSZ;
    //   this.convert();
    // },
    onGameRTSet: function () {
      this.game2Data.gameRT = { ...this.game1Data.gameRT };
      this.convert();
    },
    // onDPISet: function () {
    //   this.game2Data.dpi = this.game1Data.dpi;
    //   this.convert();
    // },
    onGame1Set: function () {
      this.gameInfo1 = GameInfo[this.game1Data.game];
      this.game1Data.mode = this.gameInfo1.modes[0];
      this.gameInfo1.onModeSet(this.game1Layout, this.game1Data, true);
      this.convert();
    },
    onGame1ModeSet: function () {
      this.gameInfo1.onModeSet(this.game1Layout, this.game1Data, true);
      this.convert();
    },
    onGame2Set: function () {
      this.gameInfo2 = GameInfo[this.game2Data.game];
      this.game2Data.mode = this.gameInfo2.modes[0];
      this.gameInfo2.onModeSet(this.game2Layout, this.game2Data, false);
      this.convert();
    },
    onGame2ModeSet: function () {
      this.gameInfo2.onModeSet(this.game2Layout, this.game2Data, false);
      this.convert();
    },

    convert: function () {
      if (!this.gameInfo1.calcSens || !this.gameInfo2.calcSens) return;
      if (this.mode == "等角转换") {
        this.gameInfo2.calcSens(
          this.gameInfo1.getSpeed(this.game1Data),
          this.game2Data
        );
        return;
      }
      let k1 = GetRatio(this.mode == "等距转换", this.dist, this.game1Data);
      let deg1 = Math.atan(k1 * this.gameInfo1.getTanFOV(this.game1Data));
      let k2 = GetRatio(this.mode == "等距转换", this.dist, this.game2Data);
      let deg2 = Math.atan(k2 * this.gameInfo2.getTanFOV(this.game2Data));
      this.gameInfo2.calcSens(
        (this.gameInfo1.getSpeed(this.game1Data) * deg2) / deg1,
        this.game2Data
      );
    },
  },
  data: () => ({
    games: Games,
    convModes: ConvModes,
    screenRT: ScreenRatio,
    gameRT: GameRatio,
    mode: "等比转换（推荐）",
    slideLabel: "舒适拉枪距离(%)",
    slideDisable: false,
    dist: 25,

    screenRTLabel: "显示器比例",
    szLabel: "无需填写",
    gameRTLabel: "游戏画面比例",
    fovLabel: "FOV",
    screenRTDisable: false,
    szDisable: true,
    gameRTDisable: false,

    gameInfo1: {},
    gameInfo2: {},

    game1Layout: {
      dt1Label: "灵敏度",
      dt2Label: "无需填写",
      dt3Label: "无需填写",
      op1Label: "无需填写",
      op2Label: "无需填写",
      op1Item: [],
      op2Item: [],
      fovDisable: true,
      dt1Disable: true,
      dt2Disable: true,
      dt3Disable: true,
      op1Disable: true,
      op2Disable: true,
      dt1RO: true,
      dt2RO: true,
      dt3RO: true,
    },
    game1Data: {
      screenRT: "",
      screenSZ: "",
      gameRT: "",
      dpi: "800",
      fov: "",
      game: "",
      mode: "腰射",
      val1: "",
      val2: "",
      val3: "",
      op1: "",
      op2: "",
    },
    game2Layout: {
      dt1Label: "灵敏度（转换结果）",
      dt2Label: "无需填写",
      dt3Label: "无需填写",
      op1Label: "无需填写",
      op2Label: "无需填写",
      op1Item: [],
      op2Item: [],
      fovDisable: true,
      dt1Disable: true,
      dt2Disable: true,
      dt3Disable: true,
      op1Disable: true,
      op2Disable: true,
      dt1RO: true,
      dt2RO: true,
      dt3RO: true,
    },
    game2Data: {
      screenRT: "",
      screenSZ: "",
      gameRT: "",
      dpi: "800",
      fov: "",
      game: "",
      mode: "腰射",
      val1: "",
      val2: "",
      val3: "",
      op1: "",
      op2: "",
    },
    eng2ch: {
      "CS:GO": "CS:GO",
      // "CS:S": "CS起源",
      // "CS 1.6": "CS 1.6",
      CrossFire: "穿越火线",
      // CFHD: "穿越火线高清竞技大区",
      "Apex Legends": "APEX英雄",
      // "Battlefield 2042": "战地2042",
      "Battlefield V": "战地5",
      "Battlefield 1": "战地1",
      // "Battlefield 4": "战地4",
      // "Battlefield 3": "战地3",
      // "Call of Duty: Vanguard": "使命召唤：先锋",
      // "Call of Duty: Black Ops Cold War": "使命召唤：黑色行动冷战",
      // "Call of Duty: Modern Warfare (2019,Warzone)":
      //   "使命召唤：现代战争2019（战区）",
      // "Call of Duty: Black Ops 4": "使命召唤：黑色行动4",
      "Rainbow Six Siege": "彩虹六号围攻",
      Overwatch: "守望先锋",
      // "Escape From Tarkov": "逃离塔科夫",
      Valorant: "瓦洛兰特",
      "Call of Duty: MWII/Warzone 2.0": "使命召唤：现代战争2022/战区2.0",
      // "Halo Infinite": "光环无限",
      // PUBG: "绝地求生大逃杀",
    },
    plan: {
      // "Battlefield 2042": "2022.2.3",
      // "Apex Legends": "2022.2.6",
      "Call of Duty: Modern Warfare (2019,Warzone)": "2022.2.7",
      "Call of Duty: Vanguard": "2022.2.9",
      "Call of Duty: Black Ops Cold War": "2022.2.11",
      PUBG: "2022.2.13",
      Valorant: "2022.2.15",
      "Rainbow Six Siege": "2022.2.17",
      CrossFire: "2022.2.18",
      CFHD: "2022.2.18",
      "Escape From Tarkov": "2022.2.20",
      "Halo Infinite": "2022.2.22",
    },
  }),
  computed: {},
};
</script>
