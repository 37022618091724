import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import MainPage from '../components/MainPage.vue'
import SensConv2 from '../components/SensConv2.vue'
import SensAdv from '../components/SensAdv.vue'
import SingleShot from '../components/SingleShot.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage
  },
  {
    path: '/conv',
    name: 'SensConv2',
    component: SensConv2
  },
  {
    path: '/advanced',
    name: 'SensAdv',
    component: SensAdv
  },
  {
    path: '/singleshot',
    name: 'SingleShot',
    component: SingleShot
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
