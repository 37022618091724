const ConvModes = [
    "等比转换（推荐）",
    "等距转换",
    "等角转换"
]


const Games = [
    "CS:GO",
    "穿越火线",
    "守望先锋",
    "战地1",
    "战地5",
    "APEX英雄",
    "彩虹六号围攻",
    "瓦洛兰特",
    "使命召唤：现代战争2022/战区2.0",

]

const ScreenRatio = [
    {
        text: "21:9",
        value: { w: 21, h: 9 }
    },
    {
        text: "16:9",
        value: { w: 16, h: 9 }
    },
    {
        text: "16:10",
        value: { w: 16, h: 10 }
    },
    {
        text: "4:3",
        value: { w: 4, h: 3 }
    },
]

const GameRatio = [
    { text: "21:9拉伸", value: { w: 21, h: 9, stretch: true } },
    { text: "21:9黑边", value: { w: 21, h: 9, stretch: false } },
    { text: "16:9拉伸", value: { w: 16, h: 9, stretch: true } },
    { text: "16:9黑边", value: { w: 16, h: 9, stretch: false } },
    { text: "16:10拉伸", value: { w: 16, h: 10, stretch: true } },
    { text: "16:10黑边", value: { w: 16, h: 10, stretch: false } },
    { text: "4:3拉伸", value: { w: 4, h: 3, stretch: true } },
    { text: "4:3黑边", value: { w: 4, h: 3, stretch: false } },
]

function clearLayout(layout, data) {
    layout.dt1Label =
        layout.dt2Label =
        layout.dt3Label =
        layout.op1Label =
        layout.op2Label = "无需填写";
    layout.op1Item = [];
    layout.op2Item = [];
    layout.fovDisable =
        layout.dt1Disable =
        layout.dt2Disable =
        layout.dt3Disable =
        layout.op1Disable =
        layout.op2Disable = true;
    layout.dt1RO = layout.dt2RO = layout.dt3RO = false;
    data.val1 = data.val2 = data.val3 = "";
    data.op1 = data.op2 = "";
}

function toRad(x) {
    return x * (Math.PI / 180);
}

// function toDeg(x) {
//     return (x * 180) / Math.PI;
// }

function filterData(x) {
    if (!x) return NaN;
    return parseFloat(x);
}

function filterResult(x) {
    return x ? x : "无效输入";
}

function vFOV2hFOV(vfov, w, h) {
    return 2 * Math.atan(w / h * Math.tan(vfov / 2))
}

function GetRatio(sameDist, dist, data) {
    if (!sameDist) return dist / 100;
    let sw = data.screenRT.w;
    let sh = data.screenRT.h;
    let size = filterData(data.screenSZ) * 2.54;
    let gw = data.gameRT.w;
    let gh = data.gameRT.h;

    let width = size * Math.cos(Math.atan(sh / sw));
    let height = size * Math.sin(Math.atan(sh / sw));
    if (sw * gh <= sh * gw) return dist * 2 / width;
    if (data.gameRT.stretch) return dist * (gw / sw) / (height * (gw / gh) / 2);
    return dist / (height * (gw / gh) / 2)
}

const GameInfo = {
    "CS:GO": {
        modes: ["腰射", "SG553,AUG", "AWP,鸟狙,连狙一倍镜", "AWP二倍镜", "鸟狙,连狙二倍镜"],

        mode2vfov: {
            "腰射": toRad(73.739795),
            "SG553,AUG": toRad(34.515877),
            "AWP,鸟狙,连狙一倍镜": toRad(30.536943),
            "AWP二倍镜": toRad(7.508333),
            "鸟狙,连狙二倍镜": toRad(11.278142)
        },

        mode2k: {
            "SG553,AUG": 0.5,
            "AWP,鸟狙,连狙一倍镜": 4 / 9,
            "AWP二倍镜": 1 / 9,
            "鸟狙,连狙二倍镜": 1 / 6
        },
        baseSpeed: 1 / 16363.6364,

        onModeSet: function (layout, data, is1) {
            clearLayout(layout, data);
            data.fov = 90;
            layout.dt1Disable = false;
            if (data.mode != "腰射") {
                layout.dt1Label = "灵敏度";
                layout.dt2Label = is1 ? "开镜灵敏度" : "开镜灵敏度（转换结果）";
                layout.dt2Disable = false;
                layout.dt2RO = is1 ? false : true;
            } else {
                layout.dt1Label = is1 ? "灵敏度" : "灵敏度（转换结果）";
                layout.dt1RO = is1 ? false : true;
            }
        },

        getTanFOV: function (data) {
            let mode = data.mode;
            let w = filterData(data.gameRT.w);
            let h = filterData(data.gameRT.h);
            return Math.tan(vFOV2hFOV(this.mode2vfov[mode], w, h) / 2)
        },

        getSpeed: function (data) {
            let mode = data.mode;
            if (!mode) return NaN;
            if (mode == "腰射") return this.baseSpeed * filterData(data.dpi) * filterData(data.val1);
            return this.baseSpeed * filterData(data.dpi) * filterData(data.val1) * this.mode2k[data.mode] * filterData(data.val2);
        },

        calcSens: function (speed, data) {
            let mode = data.mode;
            if (!mode) return NaN;
            if (mode == "腰射") {
                data.val1 = filterResult(filterData(speed) / (this.baseSpeed * filterData(data.dpi)));
                return;
            }
            data.val2 = filterResult(
                filterData(speed) / (this.baseSpeed * filterData(data.dpi) * filterData(data.val1) * this.mode2k[data.mode])
            );
        }
    },


    "穿越火线": {
        modes: ["腰射", "开镜"],

        baseSpeed: (x) => { return (0.0255 + 0.0048 * x) / 800 },
        invBaseSpeed: (x) => { return (x * 800 - 0.0255) / 0.0048 },
        baseSpeed2: (x, y) => {
            let t = 0.0008 + 0.0002 * x
            return (t * y + t) / 800
        },
        invBaseSpeed2: (x, y) => {
            let t = 0.0008 + 0.0002 * x
            return y * 800 / t - 1
        },

        onModeSet: function (layout, data, is1) {
            clearLayout(layout, data);
            data.fov = 53;
            layout.dt1Disable = false;
            if (data.mode != "腰射") {
                layout.dt1Label = "灵敏度";
                layout.dt2Label = is1 ? "开镜灵敏度" : "开镜灵敏度（转换结果）";
                layout.dt2Disable = false;
                layout.dt2RO = is1 ? false : true;
            } else {
                layout.dt1Label = is1 ? "灵敏度" : "灵敏度（转换结果）";
                layout.dt1RO = is1 ? false : true;
            }
        },

        getTanFOV: function (data) {
            let mode = data.mode;
            let w = filterData(data.gameRT.w);
            let h = filterData(data.gameRT.h);
            return Math.tan(vFOV2hFOV(mode == "腰射" ? toRad(53) : toRad(42), w, h) / 2)
        },

        getSpeed: function (data) {
            let mode = data.mode;
            if (!mode) return NaN;
            if (mode == "腰射") return this.baseSpeed(filterData(data.val1)) * filterData(data.dpi);
            return this.baseSpeed2(filterData(data.val1), filterData(data.val2)) * filterData(data.dpi);
        },

        calcSens: function (speed, data) {
            let mode = data.mode;
            if (!mode) return NaN;
            if (mode == "腰射") {
                data.val1 = filterResult(this.invBaseSpeed(filterData(speed) / filterData(data.dpi)));
                return;
            }
            data.val2 = filterResult(
                this.invBaseSpeed2(filterData(data.val1), filterData(speed)) / filterData(data.dpi)
            );
        }
    },

    "守望先锋": {
        modes: ["腰射", "艾什开镜", "黑百合,安娜开镜"],

        mode2vfov: {
            "艾什开镜": toRad(40),
            "黑百合,安娜开镜": toRad(30),
        },

        baseSpeed: 1 / 54545.4545,

        onModeSet: function (layout, data, is1) {
            clearLayout(layout, data);
            data.fov = 103;
            layout.fovDisable = false;
            layout.dt1Disable = false;
            if (data.mode != "腰射") {
                layout.dt1Label = "灵敏度";
                layout.dt2Label = is1 ? "开镜灵敏度%" : "开镜灵敏度%（转换结果）";
                layout.dt2Disable = false;
                layout.dt2RO = is1 ? false : true;
            } else {
                layout.dt1Label = is1 ? "灵敏度" : "灵敏度（转换结果）";
                layout.dt1RO = is1 ? false : true;
            }
        },

        getTanFOV: function (data) {
            let mode = data.mode;
            let w = filterData(data.gameRT.w);
            let h = filterData(data.gameRT.h);
            let theta = mode == "腰射" ? toRad(filterData(data.fov)) : vFOV2hFOV(this.mode2vfov[mode], w, h);
            return Math.tan(theta / 2);
        },

        getSpeed: function (data) {
            let mode = data.mode;
            if (!mode) return NaN;
            if (mode == "腰射") return this.baseSpeed * filterData(data.dpi) * filterData(data.val1);
            return this.baseSpeed * filterData(data.dpi) * filterData(data.val1) * filterData(data.val2) / 100;
        },

        calcSens: function (speed, data) {
            let mode = data.mode;
            if (!mode) return NaN;
            if (mode == "腰射") {
                data.val1 = filterResult(filterData(speed) / (this.baseSpeed * filterData(data.dpi)));
                return;
            }
            data.val2 = filterResult(
                filterData(speed) / (this.baseSpeed * filterData(data.dpi) * filterData(data.val1)) * 100
            );
        }
    },

    "战地1": {
        modes: ["腰射", "1倍", "1.25倍", "1.5倍",
            "2倍", "2.5倍", "3倍"
            , "3.5倍", "4倍", "5倍"
            , "6倍", "8倍", "10倍"],

        mode2vfov: {
            "1倍": toRad(55), "1.25倍": toRad(45.2), "1.5倍": toRad(38.3),
            "2倍": toRad(29.2), "2.5倍": toRad(23.5), "3倍": toRad(19.7)
            , "3.5倍": toRad(16.9), "4倍": toRad(14.8), "5倍": toRad(11.9)
            , "6倍": toRad(9.9), "8倍": toRad(7.45), "10倍": toRad(5.95)
        },

        mode2adsvfov: {
            "1倍": (x) => { return toRad(x); },
            "1.25倍": (x) => { return toRad(-5.3 + 0.9157 * x); },
            "1.5倍": (x) => { return toRad(-7.5396 + 0.8297 * x); },
            "2倍": (x) => { return toRad(-8.5542 + 0.682 * x); },
            "2.5倍": (x) => { return toRad(-8.1081 + 0.5703 * x); },
            "3倍": (x) => { return toRad(-7.3896 + 0.4885 * x); },
            "3.5倍": (x) => { return toRad(-6.6674 + 0.4248 * x); },
            "4倍": (x) => { return toRad(-6.0295 + 0.3754 * x); },
            "5倍": (x) => { return toRad(-5.0297 + 0.305 * x); },
            "6倍": (x) => { return toRad(-4.2708 + 0.2552 * x); },
            "8倍": (x) => { return toRad(-3.2781 + 0.1932 * x); },
            "10倍": (x) => { return toRad(-2.6425 + 0.1547 * x); }
        },

        baseSpeed: (x) => { return 0.006366196221249355 * x + 0.00003183142049030221; },
        invBaseSpeed: (x) => { return (x - 0.00003183142049030221) / 0.006366196221249355; },

        onModeSet: function (layout, data, is1) {
            clearLayout(layout, data);
            data.fov = 55;
            layout.fovDisable = false;
            layout.dt1Disable = false;
            if (data.mode != "腰射") {
                layout.dt1Label = "灵敏度";
                layout.dt2Label = "开镜灵敏度%";
                layout.dt2Disable = false;
                data.val2 = 100;
                layout.dt3Label = is1 ? data.mode + "缩放灵敏度%" : data.mode + "缩放灵敏度%（转换结果）";
                layout.dt3Disable = false;
                layout.dt3RO = is1 ? false : true;

                layout.op1Label = "瞄准视野";
                layout.op1Disable = false;
                layout.op1Item = ["开启", "关闭"];
                data.op1 = "关闭";
            } else {
                layout.dt1Label = is1 ? "灵敏度" : "灵敏度（转换结果）";
                layout.dt1RO = is1 ? false : true;
            }
        },

        getTanFOV: function (data) {
            let mode = data.mode;
            let w = filterData(data.gameRT.w);
            let h = filterData(data.gameRT.h);
            let fov = filterData(data.fov)
            let theta = mode == "腰射" ? vFOV2hFOV(toRad(fov), w, h) :
                (data.op1 == "关闭" ? vFOV2hFOV(this.mode2vfov[mode], w, h) : vFOV2hFOV(this.mode2adsvfov[mode](fov), w, h));
            return Math.tan(theta / 2);
        },

        getSpeed: function (data) {
            let mode = data.mode;
            if (!mode) return NaN;
            let dpi = filterData(data.dpi);
            if (mode == "腰射") return this.baseSpeed(filterData(data.val1)) * dpi;

            let fov = filterData(data.fov);
            let k = (data.op1 == "关闭" ? this.mode2vfov[mode] : this.mode2adsvfov[mode](fov)) / toRad(fov);
            return this.baseSpeed(filterData(data.val1)) * dpi * k * filterData(data.val2) / 100.0 * filterData(data.val3) / 100.0;
        },

        calcSens: function (speed, data) {
            let mode = data.mode;
            if (!mode) return NaN;
            let dpi = filterData(data.dpi);
            if (mode == "腰射") {
                data.val1 = filterResult(this.invBaseSpeed(filterData(speed) / dpi));
                return;
            }
            let fov = filterData(data.fov);
            let k = (data.op1 == "关闭" ? this.mode2vfov[mode] : this.mode2adsvfov[mode](fov)) / toRad(fov);
            //console.log(fov, this.mode2vfov[mode], k, data.op1);
            data.val3 = filterResult(
                filterData(speed) / (this.baseSpeed(filterData(data.val1)) * data.dpi * k * filterData(data.val2) / 100.0) * 100
            );
        }
    },

    "战地5": {
        modes: ["腰射", "1倍", "1.25倍", "1.5倍",
            "2倍", "2.5倍", "3倍"
            , "3.5倍", "4倍", "5倍"
            , "6倍", "8倍", "10倍"],

        mode2vfov: {
            "1倍": toRad(55), "1.25倍": toRad(45.2), "1.5倍": toRad(38.3),
            "2倍": toRad(29.2), "2.5倍": toRad(23.5), "3倍": toRad(19.7)
            , "3.5倍": toRad(16.9), "4倍": toRad(14.8), "5倍": toRad(11.9)
            , "6倍": toRad(9.9), "8倍": toRad(7.45), "10倍": toRad(5.95)
        },

        mode2adsvfov: {
            "1倍": (x) => { return toRad(x); },
            "1.25倍": (x) => { return toRad(-5.3 + 0.9157 * x); },
            "1.5倍": (x) => { return toRad(-7.5396 + 0.8297 * x); },
            "2倍": (x) => { return toRad(-8.5542 + 0.682 * x); },
            "2.5倍": (x) => { return toRad(-8.1081 + 0.5703 * x); },
            "3倍": (x) => { return toRad(-7.3896 + 0.4885 * x); },
            "3.5倍": (x) => { return toRad(-6.6674 + 0.4248 * x); },
            "4倍": (x) => { return toRad(-6.0295 + 0.3754 * x); },
            "5倍": (x) => { return toRad(-5.0297 + 0.305 * x); },
            "6倍": (x) => { return toRad(-4.2708 + 0.2552 * x); },
            "8倍": (x) => { return toRad(-3.2781 + 0.1932 * x); },
            "10倍": (x) => { return toRad(-2.6425 + 0.1547 * x); }
        },

        baseSpeed: (x) => { return 0.006366196221249355 * x + 0.00003183142049030221; },
        invBaseSpeed: (x) => { return (x - 0.00003183142049030221) / 0.006366196221249355; },

        onModeSet: function (layout, data, is1) {
            clearLayout(layout, data);
            data.fov = 55;
            layout.fovDisable = false;
            layout.dt1Disable = false;
            if (data.mode != "腰射") {
                layout.dt1Label = "灵敏度";
                layout.dt2Label = "开镜灵敏度%";
                layout.dt2Disable = false;
                data.val2 = 100;
                layout.dt3Label = is1 ? data.mode + "缩放灵敏度%" : data.mode + "缩放灵敏度%（转换结果）";
                layout.dt3Disable = false;
                layout.dt3RO = is1 ? false : true;

                layout.op1Label = "瞄准视野";
                layout.op1Disable = false;
                layout.op1Item = ["开启", "关闭"];
                data.op1 = "关闭";
            } else {
                layout.dt1Label = is1 ? "灵敏度" : "灵敏度（转换结果）";
                layout.dt1RO = is1 ? false : true;
            }
        },

        getTanFOV: function (data) {
            let mode = data.mode;
            let w = filterData(data.gameRT.w);
            let h = filterData(data.gameRT.h);
            let fov = filterData(data.fov)
            let theta = mode == "腰射" ? vFOV2hFOV(toRad(fov), w, h) :
                (data.op1 == "关闭" ? vFOV2hFOV(this.mode2vfov[mode], w, h) : vFOV2hFOV(this.mode2adsvfov[mode](fov), w, h));
            return Math.tan(theta / 2);
        },

        getSpeed: function (data) {
            let mode = data.mode;
            if (!mode) return NaN;
            let dpi = filterData(data.dpi);
            if (mode == "腰射") return this.baseSpeed(filterData(data.val1)) * dpi;

            let fov = filterData(data.fov);
            let k = (data.op1 == "关闭" ? this.mode2vfov[mode] : this.mode2adsvfov[mode](fov)) / toRad(fov);
            return this.baseSpeed(filterData(data.val1)) * dpi * k * filterData(data.val2) / 100.0 * filterData(data.val3) / 100.0;
        },

        calcSens: function (speed, data) {
            let mode = data.mode;
            if (!mode) return NaN;
            let dpi = filterData(data.dpi);
            if (mode == "腰射") {
                data.val1 = filterResult(this.invBaseSpeed(filterData(speed) / dpi));
                return;
            }
            let fov = filterData(data.fov);
            let k = (data.op1 == "关闭" ? this.mode2vfov[mode] : this.mode2adsvfov[mode](fov)) / toRad(fov);
            //console.log(fov, this.mode2vfov[mode], k, data.op1);
            data.val3 = filterResult(
                filterData(speed) / (this.baseSpeed(filterData(data.val1)) * data.dpi * k * filterData(data.val2) / 100.0) * 100
            );
        }
    },

    "APEX英雄": {
        modes: ["腰射", "1x", "2x", "3x", "4x", "6x", "8x", "10x"],

        mode2hfov43: {
            "腰射": 1,
            "1x": 0.7347,
            "2x": 0.3039,
            "3x": 0.2837,
            "4x": 0.1409,
            "6x": 0.0362,
            "8x": 0.0204,
            "10x": 0.0131
        },

        mode2k: {
            "1x": 1 / 1.246546,
            "2x": 1 / 2.146242,
            "3x": 1 / 3.265007,
            "4x": 1 / 4.374939,
            "6x": 4 / 6.585693,
            "8x": 1 / 8.791829,
            "10x": 1 / 10.996104,
        },
        baseSpeed: 1 / 16363.6364,

        toRadFov: function (fov) {
            return toRad(70 + (fov - 70) * 0.9625);
        },

        toActualHfov: function (hfov43, w, h) {
            let vfov43 = vFOV2hFOV(hfov43, 3, 4);
            return vFOV2hFOV(vfov43, w, h);
        },

        onModeSet: function (layout, data, is1) {
            clearLayout(layout, data);
            data.fov = 90;
            layout.fovDisable = false;
            layout.dt1Disable = false;
            if (data.mode != "腰射") {
                layout.dt1Label = "灵敏度";
                layout.dt2Label = data.mode + (is1 ? "缩放灵敏度" : "缩放灵敏度（转换结果）");
                layout.dt2Disable = false;
                layout.dt2RO = is1 ? false : true;
            } else {
                layout.dt1Label = is1 ? "灵敏度" : "灵敏度（转换结果）";
                layout.dt1RO = is1 ? false : true;
            }
        },

        getTanFOV: function (data) {
            let mode = data.mode;
            let w = filterData(data.gameRT.w);
            let h = filterData(data.gameRT.h);
            let fov43 = this.toRadFov(filterData(data.fov));
            let hfov43 = fov43 * this.mode2hfov43[mode];
            return Math.tan(this.toActualHfov(hfov43, w, h) / 2);
        },

        getBaseSpeed: function (data) {
            let mode = data.mode;
            let w = filterData(data.gameRT.w);
            let h = filterData(data.gameRT.h);
            let fov43 = this.toRadFov(filterData(data.fov));
            let fov = this.toActualHfov(fov43, w, h);
            let sfov = this.toActualHfov(fov43 * this.mode2hfov43[mode], w, h);
            let theta = Math.atan(2 * Math.tan(fov / 2));
            let stheta = Math.atan(2 * Math.tan(sfov / 2));
            return stheta / theta * this.baseSpeed;
        },

        getSpeed: function (data) {
            let mode = data.mode;
            if (!mode) return NaN;
            if (mode == "腰射") return this.baseSpeed * filterData(data.dpi) * filterData(data.val1);
            return this.getBaseSpeed(data) * filterData(data.dpi) * filterData(data.val1) * filterData(data.val2);
        },

        calcSens: function (speed, data) {
            let mode = data.mode;
            if (!mode) return NaN;
            if (mode == "腰射") {
                data.val1 = filterResult(filterData(speed) / (this.baseSpeed * filterData(data.dpi)));
                return;
            }
            data.val2 = filterResult(
                filterData(speed) / (this.getBaseSpeed(data) * filterData(data.dpi) * filterData(data.val1))
            );
        }
    },
    "彩虹六号围攻": {
        modes: ["腰射", "1倍", "1.5倍", "2倍", "2.5倍", "3倍", "4倍", "5倍", "12倍"],
        modeFOV: {
            "腰射": 1,
            "1倍": 0.9,
            "1.5倍": 35.4 / 60,
            "2倍": 29.4 / 60,
            "2.5倍": 25.2 / 60,
            "3倍": 0.35,
            "4倍": 0.3,
            "5倍": 13.2 / 60,
            "12倍": 5.52 / 60
        },
        mode2BaseSpeed: {
            "腰射": (x) => { return 1 / 62831.8531 * x / x },
            "1倍": (x) => { return 0.02 / (0.0116 * x * x * x - 1.5926 * x * x + 120.0785 * x + 67223.9137) },
            "1.5倍": (x) => { return 0.02 / (0.0555 * x * x * x - 7.4722 * x * x + 574.1424 * x + 94121.7300) },
            "2倍": (x) => { return 0.02 / (0.0764 * x * x * x - 10.2326 * x * x + 789.8694 * x + 111207.7144) },
            "2.5倍": (x) => { return 0.02 / (0.0956 * x * x * x - 12.7543 * x * x + 987.2290 * x + 128327.0711) },
            "3倍": (x) => { return 0.02 / (0.1210 * x * x * x - 16.1114 * x * x + 1250.0024 * x + 152585.5187) },
            "4倍": (x) => { return 0.02 / (0.1456 * x * x * x - 19.3525 * x * x + 1503.5895 * x + 177041.7845) },
            "5倍": (x) => { return 0.02 / (0.2061 * x * x * x - 27.3434 * x * x + 2128.3142 * x + 239741.3636) },
            "12倍": (x) => { return 0.02 / (0.5099 * x * x * x - 67.5347 * x * x + 5265.8982 * x + 569493.8806) }
        },
        onModeSet: function (layout, data, is1) {
            clearLayout(layout, data);
            data.fov = 60;
            layout.fovDisable = false;
            layout.dt1Disable = false;
            if (data.mode != "腰射") {
                layout.dt1Label = "水平灵敏度";
                layout.dt2Label = is1 ? "开镜灵敏度" : "开镜灵敏度（转换结果）";
                layout.dt2Disable = false;
                layout.dt2RO = is1 ? false : true;
            } else {
                layout.dt1Label = is1 ? "水平灵敏度" : "水平灵敏度（转换结果）";
                layout.dt1RO = is1 ? false : true;
            }
        },

        getTanFOV: function (data) {
            let mode = data.mode;
            let w = filterData(data.gameRT.w);
            let h = filterData(data.gameRT.h);
            let fov = filterData(data.fov)
            let theta = vFOV2hFOV(toRad(fov) * this.modeFOV[mode], w, h);
            return Math.tan(theta / 2);
        },

        getSpeed: function (data) {
            let mode = data.mode;
            if (!mode) return NaN;
            let dpi = filterData(data.dpi);
            if (mode == "腰射") return this.mode2BaseSpeed[mode](1) * filterData(data.val1) * dpi;
            let fov = filterData(data.fov);
            return this.mode2BaseSpeed[mode](fov * this.modeFOV[mode]) * filterData(data.val1) * filterData(data.val2) * dpi;
        },

        calcSens: function (speed, data) {
            speed = filterData(speed);
            let mode = data.mode;
            if (!mode) return NaN;
            let dpi = filterData(data.dpi);
            if (mode == "腰射") {
                data.val1 = filterResult(speed / (this.mode2BaseSpeed[mode](1) * dpi));
                return;
            }
            let fov = filterData(data.fov);
            data.val2 = filterResult(
                speed / (this.mode2BaseSpeed[mode](fov * this.modeFOV[mode]) * dpi * filterData(data.val1))
            );
        }
    },
    "瓦洛兰特": {
        modes: ["腰射", "战神, 奥丁, 恶灵, 刺针", "重炮, 幻象, 暴徒", "捍卫者", "间谍1x", "警长", "间谍2x"],
        mode2vfov: {
            "腰射": toRad(70.5328),
            "战神, 奥丁, 恶灵, 刺针": toRad(58.344672),
            "重炮, 幻象, 暴徒": toRad(52.434177),
            "捍卫者": toRad(42.032672),
            "间谍1x": toRad(23.876441),
            "警长": toRad(16.805817),
            "间谍2x": toRad(11.67341),
        },

        mode2base: {
            "腰射": 1 / 5142.8571,
            "战神, 奥丁, 恶灵, 刺针": 1 / 5914.2857,
            "重炮, 幻象, 暴徒": 1 / 6428.5714,
            "捍卫者": 1 / 7714.2857,
            "间谍1x": 1 / 12857.1429,
            "警长": 1 / 18000,
            "间谍2x": 1 / 25714.2857,
        },

        onModeSet: function (layout, data, is1) {
            clearLayout(layout, data);
            // data.fov = 90;
            layout.dt1Disable = false;
            if (data.mode != "腰射") {
                layout.dt1Label = "灵敏度";
                layout.dt2Label = is1 ? "开镜灵敏度" : "开镜灵敏度（转换结果）";
                layout.dt2Disable = false;
                layout.dt2RO = is1 ? false : true;
            } else {
                layout.dt1Label = is1 ? "灵敏度" : "灵敏度（转换结果）";
                layout.dt1RO = is1 ? false : true;
            }
        },

        getTanFOV: function (data) {
            let mode = data.mode;
            let w = filterData(data.gameRT.w);
            let h = filterData(data.gameRT.h);
            return Math.tan(vFOV2hFOV(this.mode2vfov[mode], w, h) / 2)
        },

        getSpeed: function (data) {
            let mode = data.mode;
            if (!mode) return NaN;
            if (mode == "腰射") return this.mode2base[mode] * filterData(data.dpi) * filterData(data.val1);
            return this.mode2base[mode] * filterData(data.dpi) * filterData(data.val1) * filterData(data.val2);
        },

        calcSens: function (speed, data) {
            let mode = data.mode;
            if (!mode) return NaN;
            if (mode == "腰射") {
                data.val1 = filterResult(filterData(speed) / (this.mode2base[mode] * filterData(data.dpi)));
                return;
            }
            data.val2 = filterResult(
                filterData(speed) / (this.mode2base[mode] * filterData(data.dpi) * filterData(data.val1))
            );
        }
    },
    "使命召唤：现代战争2022/战区2.0": {
        modes: ["腰射", "步枪机瞄/红点", "手枪机瞄/红点", "霰弹枪机瞄/红点", "冲锋枪机瞄/红点", "全息",
            "3.4x", "4.0x", "4.3x", "4.8x", "5.0x", "5.5x", "6.0x", "6.3x", "6.6x",
            "7.0x", "7.5x", "8.0x", "8.7x", "9.0x", "9.5x", "11.0x", "12.5x", "13.0x"],
        mode2hfov: {
            "步枪机瞄/红点": toRad(63.74),
            "手枪机瞄/红点": toRad(75.17),
            "霰弹枪机瞄/红点": toRad(72.93),
            "冲锋枪机瞄/红点": toRad(69.53),
            "全息": toRad(51.774),
            "3.4x": toRad(44.35),
            "4.0x": toRad(41.85),
            "4.3x": toRad(40.59),
            "4.8x": toRad(39.32),
            "5.0x": toRad(38.05),
            "6.0x": toRad(35.5),
            "6.3x": toRad(34.22),
            "6.6x": toRad(32.95),
            "7.0x": toRad(31.65),
            "7.5x": toRad(30.35),
            "8.0x": toRad(29.06),
            "8.7x": toRad(26.46),
            "9.0x": toRad(25.16),
            "9.5x": toRad(23.85),
            "11.0x": toRad(19.91),
            "12.5x": toRad(16.62),
            "13.0x": toRad(15.95)
        },
        mode2hfovfac: {
            "步枪机瞄/红点": 67.316516 / 85,
            "手枪机瞄/红点": 79.259822 / 85,
            "霰弹枪机瞄/红点": 76.920608 / 85,
            "冲锋枪机瞄/红点": 73.365379 / 85,
            "全息": 54.765508 / 85,
            "3.4x": 46.959958 / 85,
        },
        baseSpeed: 1 / 54545.4545, // hipfire 16:9 hfov80 sens 1 dpi 1 
        onModeSet: function (layout, data, is1) {
            clearLayout(layout, data);
            data.fov = 80;
            layout.fovDisable = false;
            layout.dt1Disable = false;
            layout.dt3Label = "显示器距离系数";
            layout.dt3Disable = false;
            layout.dt3RO = false;
            data.val3 = 4.0 / 3;
            layout.op1Label = "举枪视野";
            layout.op1Disable = false;
            layout.op1Item = ["影响", "独立"];
            data.op1 = "影响";
            layout.op2Label = "举枪灵敏度类型";
            layout.op2Disable = false;
            layout.op2Item = ["相对", "黑色行动传统"];//["相对", "现代战争传统", "黑色行动传统"];
            data.op2 = "相对";
            if (data.mode != "腰射") {
                layout.dt1Label = "灵敏度";
                layout.dt2Label = is1 ? "举枪灵敏度" : "举枪灵敏度（转换结果）";
                layout.dt2Disable = false;
                layout.dt2RO = is1 ? false : true;
            } else {
                layout.dt1Label = is1 ? "灵敏度" : "灵敏度（转换结果）";
                layout.dt1RO = is1 ? false : true;
            }
        },

        getFOV: function (data) {
            let mode = data.mode;
            let w = filterData(data.gameRT.w);
            let h = filterData(data.gameRT.h);

            let baseFOV = toRad(filterData(data.fov));

            if (mode != "腰射") {
                if (data.op1 == "影响" && mode in this.mode2hfovfac) {
                    baseFOV *= this.mode2hfovfac[mode];
                } else {
                    baseFOV = this.mode2hfov[mode];
                }
            }

            if (w * 9 != h * 16) baseFOV = vFOV2hFOV(vFOV2hFOV(baseFOV, 9, 16), w, h);
            return baseFOV;
        },

        getTanFOV: function (data) {
            // console.log(data.fov, this.getFOV(data) * 180 / Math.PI);
            return Math.tan(this.getFOV(data) / 2);
        },

        getRealSpeed: function (data) {
            let distRatio = filterData(data.val3);
            let realSpeed = 0;
            let fov = this.getFOV(data);
            if (data.op2 == "黑色行动传统") {
                // console.log("???", fov * 180 / Math.PI, Math.tan(fov / 2) / Math.tan(toRad(40)));
                realSpeed = this.baseSpeed *
                    Math.atan(0.0001 * 9 / 16 * Math.tan(fov / 2)) /
                    Math.atan(0.0001 * 9 / 16 * Math.tan(toRad(40)))
                    ;
            } else {
                // console.log("???", fov * 180 / Math.PI);
                realSpeed = this.baseSpeed *
                    Math.atan(distRatio * 9 / 16 * Math.tan(fov / 2)) /
                    Math.atan(distRatio * 9 / 16 * Math.tan(toRad(40)))
                    ;
            }
            return realSpeed;
        },

        getSpeed: function (data) {
            let mode = data.mode;
            if (!mode) return NaN;
            let realSpeed = this.getRealSpeed(data);
            // console.log("...",
            //     realSpeed,
            //     filterData(data.dpi),
            //     filterData(data.val1),
            //     realSpeed * filterData(data.dpi) * filterData(data.val1));
            if (mode == "腰射") return realSpeed * filterData(data.dpi) * filterData(data.val1);
            return realSpeed * filterData(data.dpi) * filterData(data.val1) * filterData(data.val2);
        },

        calcSens: function (speed, data) {
            let mode = data.mode;
            if (!mode) return NaN;
            let realSpeed = this.getRealSpeed(data);
            if (mode == "腰射") {
                data.val1 = filterResult(filterData(speed) / (realSpeed * filterData(data.dpi)));
                return;
            }
            //console.log("....", realSpeed, filterData(data.dpi), filterData(data.val1), speed);
            data.val2 = filterResult(
                filterData(speed) / (realSpeed * filterData(data.dpi) * filterData(data.val1))
            );
        }
    }
}

export {
    ConvModes,
    Games,
    ScreenRatio,
    GameRatio,
    GameInfo,
    GetRatio,
}