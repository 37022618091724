<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">练枪模式（测试版）</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="center" dense>
          <!-- <v-col class="d-flex" cols="12" sm="4">
            <v-select
              :items="aspects"
              label="Screen Aspect Ratio"
              item-value="16:9"
              v-model="aspect"
              outlined
            ></v-select>
          </v-col> -->
          <v-col class="d-flex" cols="12" sm="8">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>灵敏度设定</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-slider
                    label="灵敏度"
                    class="align-center"
                    :max="30"
                    :min="1"
                    v-model="sensitivity"
                    @change="controller.pointerSpeed = sensitivity / 20"
                  >
                    <template v-slot:append>
                      <v-text-field
                        class="mt-0 pt-0"
                        outlined
                        dense
                        single-line
                        type="number"
                        style="width: 80px"
                        v-model="sensitivity"
                      ></v-text-field>
                    </template>
                  </v-slider>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>准星设定</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- <v-col class="d-flex" cols="12" sm="3"> -->
                  <v-slider
                    dense
                    label="中心点大小"
                    class="align-center"
                    :max="10"
                    :min="1"
                    v-model="centerSize"
                    @input="genCrossHair"
                  >
                    <template v-slot:append>
                      <v-text-field
                        class="mt-0 pt-0"
                        outlined
                        dense
                        single-line
                        type="number"
                        style="width: 80px"
                        v-model="centerSize"
                      ></v-text-field>
                    </template>
                  </v-slider>
                  <!-- </v-col> -->

                  <!-- <v-col class="d-flex" cols="12" sm="3"> -->
                  <v-slider
                    dense
                    label="十字线长度"
                    class="align-center"
                    :max="10"
                    :min="1"
                    v-model="lineLength"
                    @input="genCrossHair"
                  >
                    <template v-slot:append>
                      <v-text-field
                        class="mt-0 pt-0"
                        outlined
                        dense
                        single-line
                        type="number"
                        style="width: 80px"
                        v-model="lineLength"
                      ></v-text-field>
                    </template>
                  </v-slider>
                  <!-- </v-col> -->

                  <!-- <v-col class="d-flex" cols="12" sm="3"> -->
                  <v-slider
                    dense
                    label="十字线宽度"
                    class="align-center"
                    :max="10"
                    :min="1"
                    v-model="lineWidth"
                    @input="genCrossHair"
                  >
                    <template v-slot:append>
                      <v-text-field
                        class="mt-0 pt-0"
                        outlined
                        dense
                        single-line
                        type="number"
                        style="width: 80px"
                        v-model="lineWidth"
                      ></v-text-field>
                    </template>
                  </v-slider>
                  <!-- </v-col> -->

                  <!-- <v-col class="d-flex" cols="12" sm="3"> -->
                  <v-slider
                    dense
                    label="十字线间距"
                    class="align-center"
                    :max="10"
                    :min="1"
                    v-model="lineDiff"
                    @input="genCrossHair"
                  >
                    <template v-slot:append>
                      <v-text-field
                        class="mt-0 pt-0"
                        outlined
                        dense
                        single-line
                        type="number"
                        style="width: 80px"
                        v-model="lineDiff"
                      ></v-text-field>
                    </template>
                  </v-slider>
                  <!-- </v-col> -->
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <v-row justify="center" dense>
          <v-col class="d-flex" cols="12" sm="8">
            <div id="crosshair1">
              <div id="mi1"></div>
              <div id="lf1"></div>
              <div id="up1"></div>
              <div id="do1"></div>
              <div id="ri1"></div>
            </div> </v-col
        ></v-row>

        <v-row justify="center" dense>
          <v-col class="d-flex" cols="8" sm="8">
            <v-btn
              width="100%"
              v-on:click="this.startGame"
              color="success"
              v-if="this.state == 'st'"
              >点击开始</v-btn
            >
          </v-col>
        </v-row>
        <v-row justify="center" dense>
          <v-col class="d-flex" cols="8" sm="4">
            <v-btn
              width="100%"
              v-on:click="this.continueGame"
              v-if="this.state == 'paused'"
              color="success"
              >继续</v-btn
            >
          </v-col>
          <v-col class="d-flex" cols="8" sm="4">
            <v-btn
              width="100%"
              v-on:click="this.restartGame"
              v-if="this.state == 'paused'"
              color="primary"
              >重新开始</v-btn
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row justify="center">
          <div id="cvsBg">
            <div id="uilayer">
              <div id="killcnt">得分:{{ this.killCnt }}</div>

              <div id="timer"></div>
              <div id="killratio">
                准确度:
                {{
                  ((this.killCnt / Math.max(1, this.clickCnt)) * 100).toFixed(
                    1
                  )
                }}%
              </div>
              <div id="optimer"></div>
              <div id="crosshair2">
                <div id="mi2"></div>
                <div id="lf2"></div>
                <div id="up2"></div>
                <div id="do2"></div>
                <div id="ri2"></div>
              </div>
            </div>

            <canvas id="cvs" @click="onClick"></canvas>
          </div>
        </v-row>
      </v-col>

      <v-dialog v-model="finalDialog" width="500">
        <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

        <v-card>
          <v-card-title class="text-h5 grey lighten-2"> 成绩 </v-card-title>
          <v-card-text>
            <p></p>
            <p>得分: {{ killCnt }}</p>
            <p>
              准确度:
              {{
                ((this.killCnt / Math.max(1, this.clickCnt)) * 100).toFixed(1)
              }}%
            </p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="finalDialog = false">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <v-col cols="12"
        ><v-row justify="center">
          <v-col cols="12" sm="8">
            <InnerLink></InnerLink>
          </v-col> </v-row
      ></v-col> -->
      <v-col cols="12" />

      <!-- <v-col cols="12" sm="8">
        <v-card outlined>
          <v-card-title class="display-1">使用说明</v-card-title>
          <v-card-text class="title text-left">
            &nbsp;&nbsp;&nbsp;&nbsp;
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
// import InnerLink from "./InnerLink.vue";
import * as THREE from "three";
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls";

class DownCounter {
  constructor(delta, clock) {
    this.delta = delta;
    this.clock = clock;
    this.remain = delta;
    this.next = clock.getElapsedTime() + 1;
    this.delta = 0;
    this.state = "running";
  }

  update() {
    if (this.remain == 0 || this.state != "running") return this.remain;
    let now = this.clock.getElapsedTime();
    if (now >= this.next) {
      this.remain--;
      this.next += 1;
    }
    return this.remain;
  }

  pause() {
    this.state = "paused";
    let now = this.clock.getElapsedTime();
    this.delta = this.next - now;
  }
  continue() {
    this.next = this.delta + this.clock.getElapsedTime();
    this.state = "running";
  }
}

// const SphereMaterial = new THREE.MeshLambertMaterial({ color: 0x04bef4 });

const SphereMaterial = new THREE.MeshStandardMaterial({ color: 0x04bef4 });
// const SphereMaterial = new THREE.MeshStandardMaterial();

const BackgroundMaterial = new THREE.MeshStandardMaterial({ color: 0x666666 });

export default {
  name: "SingleShot",
  // components: {
  //   InnerLink,
  // },
  metaInfo: {
    title: "在线练枪模式：单个目标", // set a title
    meta: [
      {
        // set meta
        name: "keywords",
        content:
          "Aim Trainer, \
          Aim Training, \
          Aim, \
          Aiming, \
          Online Aim Trainer, \
          Aim Practice, \
          Aim Trainer Online,\
          CSGO Aim Trainer,\
          Aim Lab,\
          AimLab,\
          Kovaak,\
          Aim Map,\
          在线练枪",
      },
      {
        // set meta
        name: "description",
        content:
          "This website is a Real 3D Aim Trainer. We\
            develop it for all of the FPS game lovers to train their aiming\
            skill in a 3D environment.",
      },
    ],
    link: [
      {
        // set link
        rel: "asstes",
        href: "http://fpshelper.com/singleshot",
      },
    ],
  },
  data: () => ({
    finalDialog: false,
    state: "st",
    canvas: null,
    cvsBg: null,
    crossHair1: null,
    crossHair2: null,
    uiLayer: null,
    opTimer: null,
    runTimer: null,
    aspects: ["16:9", "16:10", "4:3"],
    aspect: "16:9",
    sensitivity: 16,
    scene: null,
    renderer: null,
    camera: null,
    controller: null,
    raycaster: null,
    clock: null,
    counter1: null,
    counter2: null,
    audioCtx: null,
    centerSize: 1,
    lineLength: 4,
    lineWidth: 1,
    lineDiff: 4,
    timeRemain: 0,
    timeST: 0,
    targetDist: 20,
    killCnt: 0,
    clickCnt: 0,
  }),

  methods: {
    initScene: function () {
      this.raycaster = new THREE.Raycaster();
      this.raycaster.layers.set(10);
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color("#eee");
      this.camera = new THREE.PerspectiveCamera(80, 4 / 3, 10, 1000);
      let controller = new PointerLockControls(this.camera, this.canvas);
      controller.pointerSpeed = this.sensitivity / 10;
      this.controller = controller;
      this.renderer = new THREE.WebGLRenderer({
        canvas: this.canvas,
        antialias: true,
      });
      this.renderer.setSize(this.cvsBg.clientWidth, this.cvsBg.clientHeight);
      this.scene.add(this.genSphere(0, 0, -this.targetDist, 1));
      const targetObject = new THREE.Object3D();
      targetObject.position.z = -this.targetDist * 2;
      targetObject.position.x = 10;
      targetObject.position.y = -10;
      this.scene.add(targetObject);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.rotation.x = 0.5;
      directionalLight.target = targetObject;
      this.scene.add(directionalLight);

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
      this.scene.add(ambientLight);

      this.camera.position.z = 0;

      let box1 = new THREE.Mesh(
        new THREE.BoxGeometry(80, 50, 1),
        BackgroundMaterial
      );
      box1.position.z = -50;
      let box2 = new THREE.Mesh(
        new THREE.BoxGeometry(80, 1, 100),
        BackgroundMaterial
      );
      box2.position.y = 25;
      let box3 = new THREE.Mesh(
        new THREE.BoxGeometry(80, 1, 100),
        BackgroundMaterial
      );
      box3.position.y = -25;
      let box4 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 50, 100),
        BackgroundMaterial
      );
      box4.position.x = -40;
      let box5 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 50, 100),
        BackgroundMaterial
      );
      box5.position.x = 40;

      this.scene.add(box1);
      this.scene.add(box2);
      this.scene.add(box3);
      this.scene.add(box4);
      this.scene.add(box5);

      let animate = () => {
        requestAnimationFrame(animate);
        if (this.counter1) {
          let rem = this.counter1.update();
          this.opTimer.innerHTML = rem.toFixed();
          if (rem == 0) {
            this.opTimer.style.visibility = "hidden";
            this.counter1 = null;
            this.counter2 = new DownCounter(60, this.clock);
            this.state = "running";
          }
        }
        if (this.counter2) {
          let rem = this.counter2.update();
          this.runTimer.innerHTML = rem.toFixed();
          if (rem == 0) {
            this.counter2 = null;
            this.state = "over";
            document.exitFullscreen();
          }
        }
        this.renderer.render(this.scene, this.camera);
      };

      animate();
    },
    openFullScreen: function () {
      this.controller.lock();
      let elem = this.cvsBg;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
      // elem.style.left = "0";
      // elem.style.top = "0";
      elem.style.width = "100%";
      elem.style.height = "100%";
      elem.style.background = "#ff0000";

      this.canvas.style.width = "100%";
      this.canvas.style.height = "100%";

      this.renderer.setSize(screen.width, screen.height);
      this.camera.aspect = (screen.width * 1.0) / screen.height;
      this.camera.updateProjectionMatrix();

      this.uiLayer.style.visibility = "visible";
    },

    closeFullScreen: function () {
      if (this.state == "running") {
        this.counter2.pause();
        this.state = "paused";
      } else if (this.state == "ready") {
        this.counter1 = null;
        this.state = "st";
      } else {
        this.state = "st";
        this.finalDialog = true;
      }

      this.genSmallCanvas();
      this.renderer.setSize(this.cvsBg.clientWidth, this.cvsBg.clientHeight);
      this.camera.aspect = 4.0 / 3;
      this.camera.updateProjectionMatrix();
      this.uiLayer.style.visibility = "hidden";
    },

    startGame: function () {
      this.state = "ready";
      this.counter1 = new DownCounter(3, this.clock);
      this.runTimer.innerHTML = "00";
      this.openFullScreen();
      this.opTimer.style.visibility = "inherit";
      this.killCnt = this.clickCnt = 0;
    },
    continueGame: function () {
      this.state = "running";
      this.openFullScreen();
      this.counter2.continue();
    },
    restartGame: function () {
      this.counter2 = null;
      this.startGame();
    },

    // onOpen: function () {
    //   this.state = "running";
    //   this.openFullScreen();
    //   this.opTimer.style.visibility = "visible";
    //   this.counter1 = new DownCounter(3, this.clock);
    // },

    genSmallCanvas: function () {
      // this.cvsBg.style.position = "relative";
      // this.cvsBg.style.left = ((window.innerWidth - 800) / 2).toFixed() + "px";
      // console.log(this.cvsBg.style.left);
      // this.cvsBg.style.width = "800px";
      this.cvsBg.style.width = this.crossHair1.clientWidth.toFixed() + "px";
      console.log(this.crossHair1.clientWidth, this.cvsBg.clientWidth);
      this.cvsBg.style.height =
        ((this.cvsBg.clientWidth * 3) / 4).toFixed() + "px";
      this.cvsBg.style.background = "#ff0000";
      // this.canvas.style.position = "relative";
      this.canvas.style.left = "0";
      this.canvas.style.top = "0";
      this.canvas.style.width = this.cvsBg.clientWidth.toFixed() + "px"; //"800px";
      this.canvas.style.height = this.cvsBg.clientHeight.toFixed() + "px"; //"600px";
    },

    genCrossHair: function () {
      if (!this.crossHair1) return;
      for (let i = 1; i <= 2; i++) {
        let swidth = i == 1 ? this.crossHair1.clientWidth : screen.width;
        let halfwidth = swidth / 2;
        let sheight = i == 1 ? this.crossHair1.clientHeight : screen.height;
        let halfheight = sheight / 2;
        let mid = document.getElementById("mi" + i.toString()).style;
        let left = document.getElementById("lf" + i.toString()).style;
        let up = document.getElementById("up" + i.toString()).style;
        let down = document.getElementById("do" + i.toString()).style;
        let right = document.getElementById("ri" + i.toString()).style;
        mid.background =
          left.background =
          right.background =
          up.background =
          down.background =
            "green";
        mid.position =
          left.position =
          right.position =
          up.position =
          down.position =
            i == 1 ? "absolute" : "fixed";
        mid.width = (this.centerSize * 2 + (swidth % 2)).toFixed() + "px";
        mid.height = (this.centerSize * 2 + (sheight % 2)).toFixed() + "px";
        mid.left = (Math.floor(halfwidth) - this.centerSize).toFixed() + "px";
        mid.top = (Math.floor(halfheight) - this.centerSize).toFixed() + "px";

        left.width =
          right.width =
          up.height =
          down.height =
            this.lineLength.toFixed() + "px";
        left.height = right.height =
          (this.lineWidth * 2 + (sheight % 2)).toFixed() + "px";
        up.width = down.width =
          (this.lineWidth * 2 + (swidth % 2)).toFixed() + "px";
        left.left =
          (
            Math.floor(halfwidth) -
            this.centerSize -
            this.lineLength -
            this.lineDiff
          ).toFixed() + "px";
        right.left =
          (Math.ceil(halfwidth) + this.centerSize + this.lineDiff).toFixed() +
          "px";
        up.left = (Math.floor(halfwidth) - this.lineWidth).toFixed() + "px";
        down.left = (Math.floor(halfwidth) - this.lineWidth).toFixed() + "px";
        left.top = (Math.floor(halfheight) - this.lineWidth).toFixed() + "px";
        right.top = (Math.floor(halfheight) - this.lineWidth).toFixed() + "px";
        up.top =
          (
            Math.floor(halfheight) -
            this.lineLength -
            this.centerSize -
            this.lineDiff
          ).toFixed() + "px";
        down.top =
          (Math.ceil(halfheight) + this.centerSize + this.lineDiff).toFixed() +
          "px";
      }
    },

    genUI: function () {
      let swidth = screen.width;
      let sheight = screen.height;
      //this.opTimer.style.position = "fixed"
      // this.opTimer.background = "black";
      this.opTimer.style.fontSize = "160px";
      this.opTimer.style.textAlign = "center";
      this.opTimer.style.lineHeight = "200px";
      this.opTimer.style.width = "200px";
      this.opTimer.style.height = "200px";
      this.opTimer.style.left = ((swidth - 200) / 2).toFixed() + "px";
      this.opTimer.style.top = ((sheight - 200) / 2).toFixed() + "px";
    },

    genSphere: function (x, y, z, r) {
      let ret = new THREE.Mesh(
        new THREE.SphereGeometry(r, 32, 32),
        SphereMaterial
      );
      ret.layers.enable(10);
      [ret.position.x, ret.position.y, ret.position.z] = [x, y, z];
      return ret;
    },

    genNewTarget: function () {
      let r = this.targetDist;
      let th = Math.random() * 0.6 - 0.3;
      let th2 = Math.random() * 0.6 - 0.3;
      let d = r * Math.cos(th);
      let sphere = this.genSphere(
        d * Math.sin(th2),
        r * Math.sin(th),
        -d * Math.cos(th2),
        1
      );
      this.scene.add(sphere);
    },

    onClick: function () {
      if (this.state == "running") {
        this.clickCnt++;
        let pointer = new THREE.Vector2(0, 0);
        this.raycaster.setFromCamera(pointer, this.camera);
        let ints = this.raycaster.intersectObjects(this.scene.children);
        // let ok1 = false;
        // for (let child of this.scene.children) {
        //   let ok = false;
        //   for (let i = 0; i < ints.length; i++) {
        //     if (ints[0].object.id == child.id) {
        //       ok = true;
        //       break;
        //     }
        //   }
        //   if (ok && child.material) {
        //     // child.material.color.set(ok ? 0xff0000 : 0x00ff00);
        //     this.killCnt++;
        //     this.scene.remove(child);
        //     child.geometry.dispose();
        //     this.genNewTarget();
        //     ok1 = true;
        //     break;
        //   }
        // }

        // ok1 ? this.playSound(300) : this.playSound(200);

        if (ints.length > 0) {
          this.killCnt++;
          let obj = ints[0].object;
          this.scene.remove(obj);
          obj.geometry.dispose();
          this.genNewTarget();
          this.playSound(300);
        } else {
          this.playSound(200);
        }
      }
    },
    playSound: function (freq) {
      let audioCtx = this.audioCtx;
      let oscillator = audioCtx.createOscillator();
      let gainNode = audioCtx.createGain();
      oscillator.connect(gainNode);
      gainNode.connect(audioCtx.destination);
      oscillator.type = "sine";
      oscillator.frequency.value = freq;
      gainNode.gain.setValueAtTime(1, audioCtx.currentTime);
      oscillator.start(audioCtx.currentTime);
      oscillator.stop(audioCtx.currentTime + 0.1);
    },
  },
  mounted() {
    this.clock = new THREE.Clock();
    this.cvsBg = document.getElementById("cvsBg");
    this.canvas = document.getElementById("cvs");
    this.crossHair1 = document.getElementById("crosshair1");
    this.crossHair2 = document.getElementById("crosshair2");
    this.uiLayer = document.getElementById("uilayer");
    this.opTimer = document.getElementById("optimer");
    this.runTimer = document.getElementById("timer");
    if ((this.crossHair1.clientWidth + screen.width) % 2 == 1)
      this.crossHair1.style.width =
        (this.crossHair1.clientWidth + 1).toFixed() + "px";
    this.crossHair1.style.height = screen.height % 2 == 1 ? "81px" : "80px";
    this.genCrossHair();
    this.genSmallCanvas();
    this.initScene();
    this.genUI();

    this.uiLayer.style.visibility = "hidden";
    window.addEventListener("fullscreenchange", () => {
      if (!document.fullscreenElement) this.closeFullScreen();
    });

    this.audioCtx = new AudioContext();
  },
};
</script>

<style scoped>
#crosshair1 {
  background: black;
  position: relative;
  width: 100%;
}

#uilayer {
  position: fixed;
}

#timer {
  background: gray;
  position: fixed;
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
  height: 80px;
  font-size: 60px;
  text-align: center;
}

#killcnt {
  position: fixed;
  background: gray;
  width: 30%;
  height: 80px;
  font-size: 40px;
  line-height: 80px;
  text-align: center;
}

#killratio {
  position: fixed;
  margin-left: 70%;
  background: gray;
  width: 30%;
  height: 80px;
  font-size: 40px;
  line-height: 80px;
  text-align: center;
}

#optimer {
  position: fixed;
  z-index: 2;
  border-radius: 40px;
  background: grey;
  opacity: 0.9;
}
</style>